<template>
  <v-dialog
    v-model="isOpen"
    :max-width="dialogWidth"
    :min-width="350"
    :width="'auto'"
    class="customers-add-item-dialog"
    id="customers-add-item-dialog"
    :overlay-color="'#000000'"
    :overlay-opacity="0.9"
    ><v-form v-model="isFormValid">
      <v-card
        :max-width="dialogWidth"
        :min-width="350"
        :width="'auto'"
        class="pr-9 pl-8 pt-2 pb-4"
        min-height="450"
        v-if="selectedItem !== 'APK'"
      >
        <v-icon class="float-right mt-3 mr-n5" @click="isOpen = false"
          >mdi-close</v-icon
        >

        <v-card-title class="pl-6">
          <h5 class="white--text font-weight-light ml-n6">
            Add Monitoring Item
          </h5>
        </v-card-title>

        <v-row class="pa-0">
          <v-col cols="10" class="mt-6">
            <v-select
              style="max-width: 200px"
              :items="itemTypes"
              v-model="selectedItem"
              label="Type"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <help-button
              class="mt-10 ml-n5"
              :side="'right'"
              :title="title"
              :content="content"
              v-if="submitted == false"
            ></help-button>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col cols="11" v-if="submitted == false">
            <div v-if="selectedItem == 'Organization Names'">
              <v-textarea
                no-resize
                outlined
                :rules="OrganizationNamesRules"
                v-model="OrganizationNames"
                placeholder="GroupSense Inc. NOT GS-Inc"
                label="Organization Names"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Domains'">
              <v-textarea
                no-resize
                outlined
                :rules="DomainsRules"
                v-model="Domains"
                placeholder="groupsense.io NOT groupsense-marketing-blitz.com"
                label="Domains"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'IP Address'">
              <v-textarea
                no-resize
                outlined
                :rules="IPAddressRules"
                v-model="IPAddress"
                placeholder="4.2.2.2, 8.8.8.8"
                label="IP Address"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Email Domain'">
              <v-textarea
                no-resize
                outlined
                :rules="EmailDomainRules"
                v-model="EmailDomain"
                placeholder="groupsense.io"
                label="Email Domain"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Email Host'">
              <v-textarea
                no-resize
                outlined
                :rules="EmailHostRules"
                v-model="EmailHost"
                placeholder="mx.groupsense.io"
                label="Email Host"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Email IP Address'">
              <v-textarea
                no-resize
                outlined
                :rules="EmailIPAddressRules"
                v-model="EmailIPAddress"
                placeholder="172.217.197.27, 64.233.186.27"
                label="Email IP Address"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'CIDR'">
              <v-textarea
                no-resize
                outlined
                :rules="CIDRRules"
                v-model="CIDR"
                placeholder="4.2.2.0/24, 159.62.0.0/16"
                label="CIDR"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'ASN'">
              <v-textarea
                no-resize
                outlined
                :rules="ASNRules"
                v-model="ASN"
                placeholder="AS12345, AS23456, AS34567"
                label="ASN"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Whitelisted APK Hash'">
              <v-textarea
                no-resize
                outlined
                :rules="WhitelistedAPKHashRules"
                v-model="WhitelistedAPKHash"
                placeholder="hash1, hash2, hash3"
                label="Whitelisted APK Hash"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Host (Subdomain)'">
              <v-textarea
                no-resize
                outlined
                :rules="HostRules"
                v-model="Host"
                placeholder="host.groupsense.io, subdomain.groupsense.io"
                label="Host (Subdomain)"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Digital Asset Query'">
              <v-textarea
                no-resize
                outlined
                :rules="DigitalAssetQueryRules"
                v-model="DigitalAssetQuery"
                placeholder="http.title:'Hacked by' country:'US' state:'UT'"
                label="Digital Asset Query"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Domain of Interest'">
              <v-textarea
                no-resize
                outlined
                :rules="DomainOfInterestRules"
                v-model="DomainOfInterest"
                placeholder="Acceptable data formats: comma, space, line return"
                label="Domain of Interest"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Domain Search Term'">
              <v-textarea
                no-resize
                outlined
                :rules="DomainSearchTermRules"
                v-model="DomainSearchTerm"
                placeholder="'groupsensecyber','gorecon','tracelight'"
                label="Domain Search Term"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Domain Search Query'">
              <v-textarea
                no-resize
                outlined
                :rules="DomainSearchQueryRules"
                v-model="DomainSearchQuery"
                placeholder="'domain:/.*(nyc).*/ AND domain:/.*(election)*./'"
                label="Domain Search Query"
                style="min-width: 290px"
              ></v-textarea>
            </div>
            <div v-if="selectedItem == 'Dark Recon Query'">
              <v-textarea
                no-resize
                outlined
                :rules="DarkReconQueryRules"
                v-model="DarkReconQuery"
                placeholder=""
                style="min-width: 290px"
                label="Dark Recon Query"
              ></v-textarea>
            </div>
          </v-col>
          <v-col
            cols="11"
            v-if="submitted == true"
            id="submissionResult"
            style="max-height: 500px; min-height: 200px; overflow-y: auto"
          >
            <v-row
              v-if="submissionResult.length > 1"
              class="ma-auto mb-2"
              v-html="submissionResult"
            ></v-row>
          </v-col>
        </v-row>
        <v-row class="text-center justify-center">
          <v-card-actions>
            <v-btn
              color="#E86051"
              depressed
              width="120"
              height="34"
              @click="createItem"
              v-if="submitted == false"
              :disabled="!isFormValid"
            >
              Submit
            </v-btn>
            <v-btn
              color="#E86051"
              depressed
              width="120"
              height="34"
              disabled
              v-if="submitted == true"
            >
              Submit
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-row>
      </v-card>

      <v-card
        :max-width="dialogWidth"
        :min-width="350"
        :width="'auto'"
        class="pa-0"
        min-height="300"
        v-if="selectedItem == 'APK'"
      >
        <v-card-title class="pl-6">
          <h4 class="white--text font-weight-light ml-2">
            Add Monitoring Item
          </h4>
        </v-card-title>

        <v-row class="pa-0">
          <v-col cols="10" style="max-width: 200px; margin-left: 30px">
            <v-select
              :items="itemTypes"
              v-model="selectedItem"
              label="Type"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <help-button
              class="mt-5 mr-10"
              :side="'right'"
              :title="title"
              :content="content"
              v-if="submitted == false"
            ></help-button>
          </v-col>
        </v-row>

        <v-row class="pa-0">
          <v-col
            cols="11"
            v-if="submitted == false"
            style="max-width: 200px; margin-left: 30px"
          >
            <div style="background: transparent; padding: 0px; margin-top: 1em">
              <v-text-field
                v-model="AppName"
                label="App Name"
                class="ma-0 pa-0"
                placeholder="Example Application"
                :rules="generalRule"
              ></v-text-field>
              <v-text-field
                v-model="DownloadURL"
                label="Download URL"
                class="ma-0 pa-0"
                placeholder="third-party-apps.com/example-app/example-app-download.apk"
                :rules="generalRule"
              ></v-text-field>
              <v-text-field
                v-model="LandingPageURL"
                label="LandingPage URL"
                class="ma-0 pa-0"
                placeholder="example1.com"
                :rules="generalRule"
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="11" v-if="submitted == true"> </v-col>
        </v-row>

        <v-row
          class="pa-0 pb-0"
          no-gutters
          style="background: #112748; margin-top: 0.5em"
        >
          <v-col
            cols="11"
            v-if="submitted == false"
            style="max-width: 200px; margin-left: 30px"
          >
            <div>
              <div style="margin-top: 2em"></div>
              <v-text-field
                v-model="NumberReviews"
                label="Number Reviews"
                class="ma-0 pa-0"
                placeholder="50 reviews"
                :rules="generalRule"
              ></v-text-field>
              <v-text-field
                v-model="NumberDownloads"
                label="Number Downloads"
                class="ma-0 pa-0"
                placeholder="50 downloads"
                :rules="generalRule"
              ></v-text-field>
              <v-text-field
                v-model="VersionNumber"
                label="Version Number"
                class="ma-0 pa-0"
                placeholder="version 1.0"
                :rules="generalRule"
              ></v-text-field>
              <v-text-field
                v-model="PublisherName"
                label="Publisher Name"
                class="ma-0 pa-0"
                placeholder="example publisher"
                :rules="generalRule"
              ></v-text-field>
              <v-text-field
                v-model="Rating"
                label="Rating"
                class="ma-0 pa-0"
                placeholder="example rating"
                :rules="generalRule"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            cols="11"
            v-if="submitted == true"
            id="submissionResult"
            style="max-height: 500px; min-height: 200px; overflow-y: auto"
          >
            <div
              v-if="submissionResult.length > 1"
              v-html="submissionResult"
            ></div>
          </v-col>
        </v-row>
        <v-row class="text-center justify-center">
          <v-card-actions>
            <v-btn
              color="#E86051"
              depressed
              width="120"
              height="34"
              @click="createItem"
              :disabled="!isFormValid"
              v-if="submitted == false"
            >
              Submit
            </v-btn>
            <v-btn
              color="#E86051"
              depressed
              width="120"
              height="34"
              disabled
              v-if="submitted == true"
            >
              Submit
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <p>&nbsp;</p>
        </v-row>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import store from "@/store/index";
import { createMonitoringItem } from "@/clients/tracelight";
import {
  cidrList,
  ip4List,
  defaultList,
  defaultListAllowSpaces,
  validUrl,
  noProtocolDomain,
  asnList,
} from "@/clients/validators";
import HelpButton from "@/components/HelpButton.vue";
import EventBus from "@/clients/event-bus";

export default {
  name: "customers-add-item-dialog",
  components: {
    HelpButton,
  },
  data: () => ({
    isFormValid: false,
    generalRule: [(value) => value.length > 0 || "Please enter content."],
    OrganizationNamesRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return defaultListAllowSpaces(value)[0] || "Invalid format.";
      },
    ],
    DomainsRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        let splitValue = value.split(",");
        let validate = [];
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          validate.push(noProtocolDomain(elementTrim));
        });
        return !validate.includes(false) || "Invalid domain name.";
      },
    ],
    IPAddressRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return ip4List(value)[0] || "Invalid format.";
      },
    ],
    EmailDomainRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        let splitValue = value.split(",");
        let validate = [];
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          validate.push(noProtocolDomain(elementTrim));
        });
        return !validate.includes(false) || "Invalid domain name.";
      },
      (value) => {
        let splitValue = value.split(",");
        let validate = [];
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          const periods = ["."];
          let count = 0;
          elementTrim
            .split("")
            .forEach((v) => (periods.some((c) => c == v) ? count++ : null));
          if (count >= 1) {
            validate.push(true);
          } else {
            validate.push(false);
          }
        });
        return !validate.includes(false) || "Invalid email domain name.";
      },
    ],
    EmailHostRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        let splitValue = value.split(",");
        let validate = [];
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          validate.push(validUrl(elementTrim));
        });
        return !validate.includes(false) || "Invalid format.";
      },
      (value) => {
        let splitValue = value.split(",");
        let validate = [];
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          const periods = ["."];
          let count = 0;
          elementTrim
            .split("")
            .forEach((v) => (periods.some((c) => c == v) ? count++ : null));
          if (count >= 2) {
            validate.push(true);
          } else {
            validate.push(false);
          }
        });
        return !validate.includes(false) || "Invalid email host.";
      },
    ],
    EmailIPAddressRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return ip4List(value)[0] || "Invalid format.";
      },
    ],
    CIDRRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return cidrList(value)[0] || "Invalid format.";
      },
    ],
    ASNRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return asnList(value)[0] || "Invalid ASN format.";
      },
    ],
    WhitelistedAPKHashRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return defaultList(value)[0] || "Invalid format.";
      },
    ],
    HostRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        let splitValue = value.split(",");
        let validate = [];
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          validate.push(noProtocolDomain(elementTrim));
        });
        return !validate.includes(false) || "Invalid subdomain name.";
      },
      (value) => {
        let splitValue = value.split(",");
        let validate = [];
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          const periods = ["."];
          let count = 0;
          elementTrim
            .split("")
            .forEach((v) => (periods.some((c) => c == v) ? count++ : null));
          if (count >= 2) {
            validate.push(true);
          } else {
            validate.push(false);
          }
        });
        return !validate.includes(false) || "Invalid subdomain name.";
      },
    ],
    DigitalAssetQueryRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return defaultListAllowSpaces(value)[0] || "Invalid format.";
      },
    ],
    DomainSearchTermRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return defaultList(value)[0] || "Invalid format.";
      },
    ],
    DomainSearchQueryRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        return defaultList(value)[0] || "Invalid format.";
      },
    ],
    DomainOfInterestRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        let splitValue = value.split(",");
        let validate1;
        let validate2;
        let validate3;
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          validate1 = defaultList(elementTrim);
          validate2 = noProtocolDomain(elementTrim);
          validate3 = elementTrim.startsWith("www.");
          if (!(validate1 && validate2 && !validate3)) {
            return false;
          }
        });
        return (validate1 && validate2 && !validate3) || "Invalid format.";
      },
    ],
    DarkReconQueryRules: [
      (value) => value.length > 0 || "Please enter content.",
      (value) => {
        let splitValue = value.split(",");
        let validate1;
        let validate2;
        let validate3;
        let validate4;
        let validate5;
        splitValue.forEach((element) => {
          const elementTrim = element.trim();
          validate1 = defaultListAllowSpaces(elementTrim)[0];
          validate2 = elementTrim.endsWith("and");
          validate3 = elementTrim.endsWith("or");
          validate4 = elementTrim.endsWith(" ");
          validate5 = elementTrim.endsWith("&");
          if (
            !(validate1 && !validate2 && !validate3 && !validate4 && !validate5)
          ) {
            return false;
          }
        });
        return (
          (validate1 && !validate2 && !validate3 && !validate4 && !validate5) ||
          "Invalid format."
        );
      },
    ],
    itemTypes: [
      "Organization Names",
      "Domains",
      "IP Address",
      "Email Domain",
      "Email Host",
      "Email IP Address",
      "CIDR",
      "ASN",
      "Whitelisted APK Hash",
      "Host (Subdomain)",
      "Digital Asset Query",
      "Domain of Interest",
      "Domain Search Term",
      "Domain Search Query",
      "Dark Recon Query",
      "APK",
    ],
    isOpen: false,
    selectedItem: null,
    submitted: false,
    title: "",
    content: "",
    OrganizationNames: "",
    Domains: "",
    IPAddress: "",
    EmailDomain: "",
    EmailHost: "",
    EmailIPAddress: "",
    CIDR: "",
    ASN: "",
    WhitelistedAPKHash: "",
    Host: "",
    DigitalAssetQuery: "",
    DomainOfInterest: "",
    DomainSearchTerm: "",
    DomainSearchQuery: "",
    DarkReconQuery: "",
    AppName: "",
    LandingPageURL: "",
    DownloadURL: "",
    PublisherName: "",
    Rating: "",
    VersionNumber: "",
    NumberDownloads: "",
    NumberReviews: "",
    body: [],
    submissionResult: "",
    dialogWidth: 350,
  }),
  mounted() {
    this.selectedItem = "Organization Names";
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    requestOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    monitoringItemTypes() {
      return store.state.customerList.itemTypes;
    },
  },
  methods: {
    errorBorder(msg) {
      console.log(msg);
    },

    createItem() {
      this.body = [];
      this.submissionResult = "";
      this.submitted = true;
      const clientId = this.$route.params.clientId;

      if (this.selectedItem == "Organization Names") {
        this.createItemProcess(this.OrganizationNames, "organization_name");
      }

      if (this.selectedItem == "Domains") {
        this.createItemProcess(this.Domains, "domain");
      }

      if (this.selectedItem == "Host (Subdomain)") {
        this.createItemProcess(this.Host, "host");
      }

      if (this.selectedItem == "IP Address") {
        this.createItemProcess(this.IPAddress, "ip_address");
      }

      if (this.selectedItem == "Email Domain") {
        this.createItemProcess(this.EmailDomain, "email_domain");
      }

      if (this.selectedItem == "Email Host") {
        this.createItemProcess(this.EmailHost, "email_host");
      }

      if (this.selectedItem == "Email IP Address") {
        this.createItemProcess(this.EmailIPAddress, "email_ip_address");
      }

      if (this.selectedItem == "CIDR") {
        this.createItemProcess(this.CIDR, "cidr");
      }

      if (this.selectedItem == "ASN") {
        this.createItemProcess(this.ASN, "asn");
      }

      if (this.selectedItem == "Whitelisted APK Hash") {
        this.createItemProcess(this.WhitelistedAPKHash, "whitelisted_apk_hash");
      }

      if (this.selectedItem == "Digital Asset Query") {
        this.createItemProcess(this.DigitalAssetQuery, "asset_query");
      }

      if (this.selectedItem == "Domain Search Term") {
        this.createItemProcess(this.DomainSearchTerm, "domain_search_term");
      }

      if (this.selectedItem == "Domain of Interest") {
        this.createItemProcess(this.DomainOfInterest, "domain_of_interest");
      }

      if (this.selectedItem == "Domain Search Query") {
        this.createItemProcess(this.DomainSearchQuery, "domain_search_query");
      }

      if (this.selectedItem == "Dark Recon Query") {
        this.createItemProcess(this.DarkReconQuery, "darkrecon_search_query");
      }

      if (this.selectedItem == "APK") {
        let apkData = {
          app_name: this.AppName,
          landing_page_url: this.LandingPageURL,
          download_url: this.DownloadURL,
          publisher_name: this.PublisherName,
          rating: this.Rating,
          version_number: this.VersionNumber,
          num_downloads: this.NumberDownloads,
          num_reviews: this.NumberReviews,
        };
        this.body.push({
          item_type: "jWSmpCPJQyKjd-Z2EZ32rw",
          value: apkData,
        });
      }

      const th = this;

      if (th.body.length > 0) {
        createMonitoringItem(th.body, { client_id: clientId }).then(function (
          result
        ) {
          if (result.data.data.failed_validation.length > 0) {
            for (const item of result.data.data.failed_validation) {
              th.submissionResult +=
                `<table><tbody>` +
                "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Failed Validation: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                item.error;
              ("</td></tr></tbody>");
            }
          }

          if (result.data.data.already_exists.length > 0) {
            for (let c = 0; c < result.data.data.already_exists.length; c++) {
              let item = result.data.data.already_exists[c];

              if (item.item_type == 19) {
                th.submissionResult +=
                  `<table><tbody>` +
                  "<tr><td style='white-space: nowrap;color:#58ABE7;width:160px'>Already Submitted</td><td</td><tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>App Name: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.app_name +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Landing Page URL: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.landing_page_url +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Download URL: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.download_url +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Publisher Name: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.publisher_name +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Rating: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.rating +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Version Number: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.version_number +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Number of Downloads: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.num_downloads +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Number of Reviews: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.num_reviews +
                  "</td></tr>" +
                  `</tbody></table>`;
              } else {
                th.submissionResult +=
                  `<table style="width: 100%;"><tbody><tr><td style="white-space: nowrap;color:#58ABE7;width:160px">Already Submitted</td>
                    <td class="textWidth" style="margin-right:20px;color:#ffffff;white-space: nowrap;">` +
                  item.value.value +
                  `</td></tr></tbody></table>`;
              }
            }
          }

          if (result.data.data.created.length > 0) {
            for (let c = 0; c < result.data.data.created.length; c++) {
              let item = result.data.data.created[c];

              if (item.item_type == 19) {
                th.submissionResult +=
                  `<table><tbody>` +
                  "<tr><td style='white-space: nowrap;color:#58ABE7;width:160px'>Added Successfully</td><td</td><tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>App Name: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.app_name +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Landing Page URL: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.landing_page_url +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Download URL: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.download_url +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Publisher Name: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.publisher_name +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Rating: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.rating +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Version Number: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.version_number +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Number of Downloads: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.num_downloads +
                  "</td></tr>" +
                  "<tr><td style='white-space: nowrap;margin-right:20px;color:#ffffff;min-width:180px;'>Number of Reviews: </td><td class='textWidth' style='color:#ffffff;white-space: nowrap;'>" +
                  item.value.value.num_reviews +
                  "</td></tr>" +
                  `</tbody></table>`;
              } else {
                th.submissionResult +=
                  `<table style="width: 100%;"><tbody><tr><td style="white-space: nowrap;color:#58ABE7;width:160px">Added Successfully</td>
                    <td class="textWidth" style="margin-right:20px;color:#ffffff;white-space: nowrap;">` +
                  item.value.value +
                  `</td></tr></tbody></table>`;
              }
            }
          }
          th.selectedItem = "";
          EventBus.$emit("customer_item_added_refresh_list", result.data.data);
        });
      }
    },

    createItemProcess(itemName, itemValue) {
      console.log(itemName, itemValue);
      let splitOrgName = itemName.split(",");
      splitOrgName.forEach((element) => {
        let elementTrim = element.trim();
        let typeId = this.monitoringItemTypes.filter(function (type) {
          return type.name == itemValue;
        });
        console.log(this.body, typeId, this.monitoringItemTypes);
        if (typeId.length > 0) {
          this.body.push({
            item_type: typeId[0].id,
            value: elementTrim,
          });
        }
      });
    },
  },
  watch: {
    submissionResult(val) {
      if (val.length > 1) {
        let th = this;
        let lengths = [];
        setTimeout(() => {
          let contentWidth = document.getElementsByClassName("textWidth");
          if (contentWidth != null) {
            for (let x = 0; x < contentWidth.length; x++) {
              const c = contentWidth[x];
              console.debug(c.offsetWidth);
              lengths.push(c.offsetWidth);
            }

            let contentWidthMax = Math.max.apply(
              Math,
              lengths.map(function (o) {
                return o;
              })
            );

            th.dialogWidth = contentWidthMax + 350;
          }
        }, 50);
      } else {
        this.dialogWidth = 350;
      }
    },

    requestOpen(val) {
      if (val == true) {
        this.isOpen = true;
      }
    },
    isOpen(val) {
      if (val == false) {
        this.OrganizationNames = "";
        this.Domains = "";
        this.IPAddress = "";
        this.EmailDomain = "";
        this.EmailHost = "";
        this.EmailIPAddress = "";
        this.CIDR = "";
        this.ASN = "";
        this.WhitelistedAPKHash = "";
        this.Host = "";
        this.selectedItem = "Organization Names";
        this.submitted = false;
        this.submissionResult = "";
        this.body = [];
        this.DigitalAssetQuery = "";
        this.DomainOfInterest = "";
        this.DomainSearchTerm = "";
        this.DomainSearchQuery = "";
        this.DarkReconQuery = "";
        this.AppName = "";
        this.LandingPageURL = "";
        this.DownloadURL = "";
        this.PublisherName = "";
        this.Rating = "";
        this.VersionNumber = "";
        this.NumberDownloads = "";
        this.NumberReviews = "";
      }
    },
    selectedItem(val) {
      if (val.length > 1) {
        this.submitted = false;
        this.OrganizationNames = "";
        this.Domains = "";
        this.IPAddress = "";
        this.EmailDomain = "";
        this.EmailHost = "";
        this.EmailIPAddress = "";
        this.CIDR = "";
        this.ASN = "";
        this.WhitelistedAPKHash = "";
        this.Host = "";
        this.DigitalAssetQuery = "";
        this.DomainOfInterest = "";
        this.DomainSearchTerm = "";
        this.DomainSearchQuery = "";
        this.DarkReconQuery = "";
        this.AppName = "";
        this.LandingPageURL = "";
        this.DownloadURL = "";
        this.PublisherName = "";
        this.Rating = "";
        this.VersionNumber = "";
        this.NumberDownloads = "";
        this.NumberReviews = "";
        this.dialogWidth = 350;
        if (val == "Organization Names") {
          this.title = "Organization Names";
          this.content = `<span>Official company names based on ASN registrations.
                        Tracelight will only do an EXACT SEARCH on the
                        information entered. Validate the domain on 'shodan.io',
                        'bgp.he.net' and
                        'https://mxtoolbox.com/SuperTool.aspx?action=asn'. We
                        recommend separating subsidiaries as a discrete
                        customer.</span>`;
        }
        if (val == "Domains") {
          this.title = "Domains";
          this.content = `<span>Primary and official domains only. You may enter
                        multiple domains. Tracelight will automatically find
                        subdomains and fuzz any domains you input. Additionally,
                        Tracelight will mark any domains as "email domains" if
                        they are associated with an MX record.</span>`;
        }
        if (val == "IP Address") {
          this.title = "IP Address";
          this.content = `<span>IP addresses associated with customer digital assets.
                      These are automatically detected based on Organization
                      Name and Domain. You may manually add entries.</span>`;
        }
        if (val == "Email Domain") {
          this.title = "Email Domain";
          this.content = `<span>E-mail domains are automatically detected from primary domains, but you may manually add additional e-mail domains that are different from primary domains or were not automatically detected. You may enter multiple comma-separated domains at once. Tracelight will create Breach Research reports based on email domains and discover new hosts related to the mail servers.</span>`;
        }
        if (val == "Email Host") {
          this.title = "Email Host";
          this.content = `<span>Hostnames associated with customer mail servers. These are
                      automatically detected based on domains. You may manually
                      add entries.</span>`;
        }
        if (val == "Email IP Address") {
          this.title = "Email IP Address";
          this.content = `<span>IP Addresses associated with customer mail servers. These
                      are automatically detected based on domains. You may
                      manually add entries.</span>`;
        }
        if (val == "CIDR") {
          this.title = "CIDR";
          this.content = `<span>IP ranges associated with customer infrastructure. You may
                      manually add entries.</span>`;
        }
        if (val == "ASN") {
          this.title = "ASN";
          this.content = `<span>AS12345, AS23456, AS34567</span>`;
        }
        if (val == "Whitelisted APK Hash") {
          this.title = "Whitelisted APK Hash";
          this.content = `<span>hash1, hash2, hash3</span>`;
        }

        if (val == "Host (Subdomain)") {
          this.title = "Host (Subdomain)";
          this.content = `<span>Also known as 'sub-domains'. These are automatically
                      detected based on the domains for this customer. You may
                      manually add entries.</span>`;
        }

        if (val == "Digital Asset Query") {
          this.title = "Digital Asset Query";
          this.content = `<span>Digital Asset Queries are a pass through feature. This function
              allows users to send a query to Shodan which will fetch details on
              a regular cadence.</span>`;
        }
        if (val == "Domain of Interest") {
          this.title = "Domain of Interest";
          this.content = `<span>Enter domains that require additional investigation.
                        Tracelight will review these domains for suspicious
                        indicators such as Blacklist entries, malicious
                        indicators, and safe-browsing entries. Tracelight will
                        also collect a screenshot of the site for the analyst to
                        review.</span>`;
        }
        if (val == "Domain Search Term") {
          this.title = "Domain Search Term";
          this.content = `<span>Add specific terms related to the client. Tracelight
                        will review newly registered domains and potential
                        phishing sites based on the domain search terms
                        associated with this profile. For example, meaningful
                        domain search terms for GroupSense include 'groupsense',
                        'tracelight' but NOT 'group', 'sense', 'cyber'`;
        }
        if (val == "Domain Search Query") {
          this.title = "Domain Search Query";
          this.content = `<span>  A query for submitted domains using the Urlscan search
                        operators (see https://urlscan.io/search) to narrow down
                        the searches.
                        <br />Examples: <br />domain:/.*(nyc).*/ AND
                        domain:/.*(election)*./ <br />Contacts at least one
                        domain containing "nyc" and at least one domain
                        containing "election" (they can be the same domain)
                        <br />
                        <br />domain:/.*(vote).* AND filename:jquery
                        <br />Contacts at least one domain that contains "vote"
                        and page uses jquery
                        <br />
                        <br />domain:/.*(newyork).*/ AND !page.country:us
                        <br />Primary GeoIP is not in the US and contacts at
                        least one domain that contains "newyork"`;
        }
        if (val == "Dark Recon Query") {
          this.title = "Dark Recon Query";
          this.content = `<span> Enter a query to have Tracelight automatically run a dark
                      recon query for this client. Tracelight is already
                      searching dark recon for Organization Name, Domains, Email
                      domains so you do not have to add those keywords.</span>`;
        }
        if (val == "APK") {
          this.dialogWidth = 350;
          this.title = "APK";
          this.content = `<span><b>App Name</b><br/>The name of the application as it is published on the mobile app store.<br/><br/>
        <b>Landing Page URL</b><br/>The Landing page for the mobile apk of interest. Tracelight will take a screenshot of this page.<br/><br/>
        <b>Download URL</b><br/>The FULL download path for the APK. Tracelight will fetch the file and submit to various malware sandbox services for review.</span>`;
        }
      }
    },
  },
};
</script>
