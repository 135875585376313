const NO_MSG = "";

export const splitOnCommas = (value) => {
  return value
    .split(",")
    .map((v) => v.trim())
    .map((v) => (v === "" ? null : v))
    .filter((v) => v !== null);
};

export const splitOnNewlines = (value) => {
  const splitValue = value
    .split(/\r?\n/)
    .map((v) => v.trim())
    .map((v) => (v === "" ? null : v))
    .filter((v) => v !== null);
  return splitValue;
};

export const default_valid = (input) => {
  // a default validator method; always returns True
  return [true, NO_MSG];
};

const ip4Regex = new RegExp(
  "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$"
);

//https://regex101.com/r/cT0hV4/5
const ip6Regex = new RegExp(
  "(?:^|(?<=s))(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?=s|$)"
);

//https://stackoverflow.com/questions/14199418/regex-to-validate-as-numbers
const asnRegex = /AS\d+/;
// const asnRegex = new RegExp(
//  "^([1-5]\\d{4}|[1-9]\\d{0,3}|6[0-4]\\d{3}|65[0-4]\\d{2}|655[0-2]\\d|6553[0-5])(\\.([1-5]\\d{4}|[1-9]\\d{0,3}|6[0-4]\\d{3}|65[0-4]\\d{2}|655[0-2]\\d|6553[0-5]|0))?$"
// );

export const ip4 = (input) => {
  if (!ip4Regex.test(input)) {
    return [false, "Must be properly formatted IPv4 address: x.x.x.x"];
  } else {
    return [true, NO_MSG];
  }
};

export const asn = (input) => {
  console.log(input);
  // let stripPrefix = input.replace(/^AN/, "");
  // console.log(stripPrefix);
  if (!asnRegex.test(input)) {
    return [false, "Must be properly formatted ASN"];
  } else {
    return [true, NO_MSG];
  }
};

export const ip6 = (input) => {
  if (!ip6Regex.test(input)) {
    return [false, "Must be properly formatted IPv6 address: x.x.x.x"];
  } else {
    return [true, NO_MSG];
  }
};

export const cidr = (input) => {
  if (!input.includes("/")) {
    return [false, "Must be properly formatted: x.x.x.x/y"];
  } else {
    return [true, NO_MSG];
  }
};

export const domain = (input) => {
  if (input.includes("*")) {
    return [
      false,
      'No wildcards in domains; use the base domain (eg, example.com not *.example.com")',
    ];
  } else {
    return [true, NO_MSG];
  }
};

export const notEmpty = (input) => {
  if (input.length < 1) {
    return [false, "Value cannot be empty"];
  } else {
    return [true, NO_MSG];
  }
};

export const noSpaces = (input) => {
  if (input.includes(" ")) {
    return [
      false,
      "Value cannot include spaces - multiple values must be separated with commas",
    ];
  } else {
    return [true, NO_MSG];
  }
};

export const isoDate = (input) => {
  if (input.length < 1) {
    return "Value cannot be empty";
  } else {
    return [true, NO_MSG];
  }
};

const indicatorValues = {
  undefined: "\u{2026}",
  true: "\u{2714}",
  false: "\u{2718}",
};

export const validatePassword = (pass1) => {
  if (pass1.length < 8) {
    return undefined, undefined;
  }

  const hasLowerCase = () => {
    return pass1.toUpperCase() != pass1;
  };

  const hasUpperCase = () => {
    return pass1.toLowerCase() != pass1;
  };

  const hasNumbers = () => {
    return pass1.replace(/\D/g, "") != "";
  };

  const hasSpecialCharacters = () => {
    return (
      pass1.replace(/['!"#$%&\\'()\*+,\-\.\/:;<=>?@\[\\\]\^_`{|}~']/g, "") !=
      pass1
    );
  };

  return (
    hasLowerCase() && hasUpperCase() && hasNumbers() && hasSpecialCharacters()
  );
};

export const passwordOk = (input) => {
  if (input.length < 1) {
    return [false, indicatorValues[undefined]];
  }

  const valid = validatePassword(input);

  if (valid) {
    return [true, indicatorValues[true]];
  } else {
    return [false, indicatorValues[false]];
  }
};

export const passwordMatch = (input, passwordInput) => {
  if (input.length < 1) {
    return [false, indicatorValues[undefined]];
  }

  if (input == passwordInput) {
    return [true, indicatorValues[true]];
  } else {
    return [false, indicatorValues[false]];
  }
};

export const emptyOk = (fn) => {
  return (input) => {
    if (input === "") {
      return [true, NO_MSG];
    }
    return fn(input);
  };
};

export const listValidator = (fn, allowSpaces = false) => {
  return (input) => {
    let lastValidation;
    for (const value of splitOnCommas(input)) {
      var valid, msg;
      if (!allowSpaces) {
        [valid, msg] = noSpaces(value);
        if (!valid) {
          return [valid, msg];
        }
      }
      [valid, msg] = fn(value);
      if (!valid) {
        return [valid, msg];
      } else {
        lastValidation = [valid, msg];
      }
    }
    return lastValidation;
  };
};

export const validUrl = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const noProtocolDomain = (value) => {
  if (value.includes("--")) {
    console.log("DOUBLE HYPHEN DETECTED");
    return false;
  }

  if (value.startsWith("-")) {
    console.log("START HYPHEN DETECTED");
    return false;
  }

  if (value.length < 2 || value.length > 63) {
    console.log("LENGTH ERROR DETECTED");
    return false;
  }

  const periods = ["."];

  let periodCount = 0;

  value
    .split("")
    .forEach((v) => (periods.some((c) => c == v) ? periodCount++ : null));

  if (![1, 2].includes(periodCount)) {
    console.log("PERIOD COUNT DETECTED");
    return false;
  }

  const forbiddenCharacters = [
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    ";",
    ":",
    ",",
    "?",
    "/",
    "\\",
    "=",
    "+",
    "<",
    ">",
  ];
  let forbiddenCharacterCount = 0;
  value
    .split("")
    .forEach((v) =>
      forbiddenCharacters.some((c) => c == v) ? forbiddenCharacterCount++ : null
    );

  if (forbiddenCharacterCount >= 1) {
    console.log("FC COUNT DETECTED");
    return false;
  }

  return true;
};

export const defaultList = emptyOk(listValidator(default_valid));
export const defaultListAllowSpaces = emptyOk(
  listValidator(default_valid, true)
);
export const domainList = emptyOk(listValidator(domain));
export const cidrList = emptyOk(listValidator(cidr));
export const ip4List = emptyOk(listValidator(ip4));
export const ip6List = emptyOk(listValidator(ip4));
export const asnList = emptyOk(listValidator(asn));
