<template>
  <v-row>
    <v-col cols="3" v-for="(item, index) in itemsToRender" :key="index">
      <div class="px-6">
        <v-row>
          <v-col offset="1" cols="3" class="py-2">
            <v-icon v-if="item.icon" x-large :color="item.iconColor">{{
              item.icon
            }}</v-icon>

            <v-icon v-if="item.customIcon" x-large :color="item.iconColor">
              {{ "$vuetify.icons." + item.customIcon }}
            </v-icon>
          </v-col>
          <v-col offset="" cols="8" class="py-2">
            <v-tooltip
              color="#121D30"
              v-model="item.showToolTip"
              right
              class="ma-5"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="text-h4"
                  :style="`color: ${item.color}`"
                >
                  {{ kFormatter(item.value) }}
                </span>
              </template>
              <span>{{ item.tooltipText }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" class="pt-0">
            <div class="text-center pb-3">{{ item.type }}</div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "icon-dashboard",
  props: {
    items: {
      type: Array,
      default: function () {
        return [
          {
            icon: "mdi-earth",
            value: 0,
            type: "Type 1",
            showToolTip: false,
          },
          {
            icon: "mdi-earth",
            value: 64500,
            type: "Type 2",
            showToolTip: false,
          },
          {
            icon: "mdi-earth",
            value: 13256465,
            type: "Type 3",
            showToolTip: false,
          },
          {
            icon: "mdi-earth",
            value: 134542,
            showToolTip: false,
            type: "Type 4",
          },
          {
            icon: "mdi-earth",
            value: 6515646,
            showToolTip: false,
            type: "Type 5",
          },
          {
            icon: "mdi-earth",
            value: 1564546,
            showToolTip: false,
            type: "Type 6",
          },
          {
            icon: "mdi-earth",
            value: 564453,
            showToolTip: false,
            type: "Type 7",
          },
          {
            icon: "mdi-earth",
            value: 1154542,
            showToolTip: false,
            type: "Type 8",
          },
          {
            icon: "mdi-earth",
            value: 6515646,
            showToolTip: false,
            type: "Type 5",
          },
          {
            icon: "mdi-earth",
            value: 1564546,
            showToolTip: false,
            type: "Type 6",
          },
          {
            icon: "mdi-earth",
            value: 564453,
            showToolTip: false,
            type: "Type 7",
          },
          {
            icon: "mdi-earth",
            value: 1154542,
            showToolTip: false,
            type: "Type 8",
          },
        ];
      },
    },
  },
  computed: {
    itemsToRender() {
      return this.items.map((item) => {
        item.tooltipText = item.type;
        item.iconColor = "#58abe7";
        item.color = "#ffffff";
        if (item.value == 0) {
          item.color = "#e86051";
          item.iconColor = "#58abe7";
          item.tooltipText = "Data needed";
        }
        return item;
      });
    },
  },
  methods: {
    kFormatterDecimal(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    },
    kFormatter(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(0) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(0) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(0) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(0) + "T";
    },
  },
};
</script>
