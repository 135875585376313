<template>
  <div class="customerItems">
    <v-alert height="80" dark width="100%" prominent class="mb-8">
      <v-row class="d-flex no-gutters">
        <v-col cols="10">
          <h2 class="mt-1 font-weight-light">
            Digital Footprint - {{ this.customerName }}
          </h2>
        </v-col>

        <v-col cols="2">
          <v-btn
            color="primary"
            class="mt-1 ml-2"
            icon
            text
            @click="customerReports"
          >
            <v-icon>mdi-table-large</v-icon></v-btn
          >
          <v-btn
            color="primary"
            class="mt-1 ml-2"
            icon
            text
            @click="customerItemsChart"
          >
            <v-icon>mdi-chart-bar</v-icon></v-btn
          >
          <v-btn
            color="#E86051"
            class="mt-1 ml-2 addItemIcon"
            icon
            text
            @click="addItem"
          >
            <v-icon>mdi-plus-circle</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
    <v-dialog
      v-model="updateDialog"
      max-width="380"
      min-height="225"
      persistent
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      style="z-index: 202; background: #112748"
    >
      <v-card style="background: #112748; padding: 0em 1em 1em 0em">
        <v-card-title style="color: rgb(88, 171, 231)">Status</v-card-title>
        <v-card-text style="color: #fff">
          Please confirm you would like to
          <span v-if="selectedItem.enabled">disable</span>
          <span v-if="!selectedItem.enabled">enable</span> the following
          item:<br /><br />
          <b v-if="selectedItem.value">{{ selectedItem.value.value }}</b>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="rgb(88, 171, 231)"
            text
            style="min-width: 150px"
            @click="answerUpdateDialog('Yes')"
            >Yes</v-btn
          >
          <v-btn
            color="rgb(88, 171, 231)"
            depressed
            style="border-radius: 5px !important; min-width: 150px"
            @click="answerUpdateDialog('No')"
          >
            <span style="color: #fff">No</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <customers-add-item-dialog
      :requestOpen="openItemDialog"
    ></customers-add-item-dialog>

    <div>
      <v-row class="mt-10 mb-10" v-if="summaryPanels.length > 0">
        <icon-dashboard :items="loadedPanels" />
      </v-row>
    </div>
    <div v-if="summaryPanels.length > 0">
      <v-row class="mt-3">
        <v-col>
          <v-select
            v-model="currentType"
            :items="itemTypeOptions"
            item-value="value"
            item-text="text"
            label="Item Type"
            @input="updateCurrentType(currentType)"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="selectFilter"
            :items="[
              { name: 'Enabled', enabled: true },
              { name: 'Disabled', enabled: false },
              { name: 'All', enabled: null },
            ]"
            item-value="enabled"
            item-text="name"
            label="Status"
            @input="updateItemStatus(selectFilter)"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field v-model="q" label="Filter results"></v-text-field>
        </v-col>
        <v-col>
          <v-select
            :items="[
              { name: 'Value', value: 'value' },
              { name: 'Created', value: 'created_at' },
              { name: 'Modified', value: 'modified_at' },
            ]"
            v-model="sortBy"
            item-value="value"
            item-text="name"
            @input="updateSortBy(sortBy)"
            label="Sort by"
          ></v-select>
        </v-col>
        <v-col>
          <caption
            style="
              font-size: 0.72em;
              width: 100px;
              margin-left: -2em;
              margin-bottom: 5px;
              color: rgba(255, 255, 255, 0.7);
            "
          >
            Sort order:
          </caption>
          <v-btn-toggle
            v-model="toggleSort"
            mandatory
            style="margin-right: 80px"
          >
            <v-btn small>
              <v-icon>mdi-sort-ascending</v-icon>
            </v-btn>
            <v-btn small>
              <v-icon>mdi-sort-descending</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <p class="float-right mt-n11 ml-12" v-if="summaryItemCount == 0">
        0 Items
      </p>
      <p class="float-right mt-n11 ml-12" v-if="summaryItemCount == 1">
        {{ summaryItemCount }} Item
      </p>
      <p class="float-right mt-n11 ml-12" v-if="summaryItemCount > 1">
        {{ summaryItemCount }} Items
      </p>
      <h3 class="ml-4 mt-2 mb-2 font-weight-light" v-if="items[0]">
        {{ items[0].type.description }}
      </h3>

      <v-data-table
        disable-sort
        hide-default-footer
        :items="items"
        :headers="headers"
        :items-per-page="-1"
        :no-data-text="''"
        style="cursor: pointer"
      >
        <template v-slot:item="{ item }">
          <tr
            :id="item.id"
            @mouseenter="changeIconColors('enter', item.id)"
            @mouseleave="changeIconColors('exit', item.id)"
          >
            <td style="cursor: pointer" @click="() => updateItem(item)">
              <v-icon color="#58ABE7" medium class="mr-2" v-if="item.enabled"
                >mdi-check-circle
              </v-icon>
              <v-icon
                color="#112748"
                medium
                class="mr-2"
                style="
                  color: #112748 !important;
                  caret-color: #112748 !important;
                "
                v-if="!item.enabled"
                >mdi-checkbox-blank-circle
              </v-icon>
            </td>
            <td>{{ item.main_value }}</td>
            <td v-if="item.type">
              {{ item.type.description }}
            </td>
            <td v-else>&nbsp;</td>
            <td>{{ item.modified }}</td>
            <td>{{ item.parent }}</td>
            <td style="cursor: pointer" @click="() => linkClick(item)">
              <v-icon color="#fff" class="rowIcon">mdi-open-in-new</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" color="#58abe7" centered elevation="6">
        {{ snackBarContent }}
      </v-snackbar>
      <v-row class="mt-n6">
        <v-col cols="3"></v-col>
        <v-col cols="6">
          <v-alert class="text-center" v-if="noItems">
            <p>No results found.</p>
          </v-alert>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
    </div>
    <div v-if="summaryPanels.length == 0">
      <v-row class="mt-4">
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader type="card" height="70"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <div v-for="(row, index) in 6" :key="index" class="mt-8">
          <v-skeleton-loader
            class="mx-auto"
            max-width="960px"
            width="960px"
            type="text"
            style="margin-top: 1em; margin-bottom: 1em"
            dark
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            max-width="960px"
            width="960px"
            type="divider"
            style="margin-top: 1em; margin-bottom: 1em"
            dark
          ></v-skeleton-loader>
        </div>
      </v-row>
    </div>

    <infinite-loading
      :identifier="infiniteId"
      @infinite="infiniteHandler"
      v-if="items.length > 19"
    >
      <div slot="spinner">
        <v-row>
          <div v-for="(row, index) in 6" :key="index">
            <v-skeleton-loader
              class="mx-auto"
              max-width="960px"
              width="960px"
              type="text"
              style="margin-top: 1em; margin-bottom: 1em"
              dark
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto"
              max-width="960px"
              width="960px"
              type="divider"
              style="margin-top: 1em; margin-bottom: 1em"
              dark
            ></v-skeleton-loader>
          </div>
        </v-row>
      </div>
      <div slot="no-more" class="body-2 mt-2">&nbsp;</div>
      <div slot="no-results" class="body-2 mt-2">&nbsp;</div>
    </infinite-loading>
    <v-fade-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        outlined
        color="white"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-fade-transition>
    <div style="min-height: 600px">&nbsp;</div>
  </div>
</template>
<script>
import store from "../store/index";
import IconDashboard from "../components/IconDashboard.vue";
import {
  listItemTypes,
  listResultTypes,
  updateMonitoringItem,
} from "../clients/tracelight";
import EventBus from "@/clients/event-bus";
import CustomersAddItemDialog from "@/components/CustomersAddItemDialog.vue";
import InfiniteLoading from "vue-infinite-loading";

import { formatDate } from "@/utils/common";
export default {
  name: "CustomerItems",
  components: {
    IconDashboard,
    CustomersAddItemDialog,
    InfiniteLoading,
  },
  data: () => ({
    chartDialog: false,
    sortBy: "Value",
    sortOrder: "asc",
    toggleSort: 0,
    updateDialog: false,
    formatDate: formatDate,
    tab: null,
    clearInput: false,
    q: "",
    selectedItem: {},
    fab: false,
    openItemDialog: false,
    renderComponent: true,
    initialLoad: true,
    num: 0,
    id: null,
    snackBar: false,
    snackBarContent: "",
    search: null,
    infiniteId: 1,
    currentType: "organization_name",
    currentId: null,
    items: [],
    loadedPanels: [],
    selectFilter: "Enabled",
    type_header: null,
    headers: [
      {
        text: "Status",
        value: "htmlCellOne",
      },
      {
        text: "Value",
        value: "main_value",
      },
      {
        text: "Type",
        value: "htmlCell",
      },
      { text: "Modified", value: "modified" },

      { text: "Parent", value: "parent_value" },
      {
        text: "Details",
        value: "htmlCellTwo",
      },
    ],
  }),

  beforeMount() {
    let th = this;
    const payload = {};
    const params = {
      client_id: th.$route.params.clientId,
    };
    store.commit("customerList/replaceValue", {
      key: "clientId",
      value: this.$route.params.clientId,
    });
    listItemTypes(payload, { page: 1, results_per_page: 3000 }).then(function (
      result
    ) {
      store.commit("customerList/fillItemTypes", result.data.data);
    });
    listResultTypes(payload, { page: 1, results_per_page: 3000 }).then(
      function (result) {
        store.commit("customerList/fillResultTypes", result.data.data);
      }
    );
    const payloadSummary = {
      client_id: this.$route.params.clientId,
      enabled: true,
    };
    store
      .dispatch("customerList/getCustomerSummary", payloadSummary)
      .then((r) => this.forceRerender());
    store.dispatch("customerList/getCustomers");
  },
  mounted() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.updateUrlParams(urlParams);
    this.initialLoad = false;
    const customerItemInfo = {
      client_id: this.$route.params.clientId,
      item_type: this.currentType,
    };
    EventBus.$on("customer_item_added_refresh_list", (payload) => {
      store.dispatch("customerList/updateCustomerItems", customerItemInfo);
    });
  },
  methods: {
    updateCurrentType(value) {
      store.commit("customerList/replaceValue", {
        key: "currentItemType",
        value: value,
      });
      if (this.initialLoad == false) {
        this.loadItems();
      }
    },
    updateItemStatus(value) {
      let payloadSummary;
      if (value == null) {
        payloadSummary = {
          client_id: this.$route.params.clientId,
        };
      } else {
        payloadSummary = {
          client_id: this.$route.params.clientId,
          enabled: value,
        };
      }

      store.commit("customerList/replaceValue", {
        key: "enabled",
        value: value,
      });
      if (this.initialLoad == false) {
        store.dispatch("customerList/getCustomerSummary", payloadSummary);
        this.loadItems();
      }
    },
    updateSortBy(value) {
      store.commit("customerList/replaceValue", {
        key: "sortBy",
        value: value,
      });
      if (this.initialLoad == false) {
        this.loadItems();
      }
    },
    updateUrlParams(urlParams) {
      for (const [key, value] of urlParams.entries()) {
        const val = urlParams.get(key);

        if (key == "enabled") {
          store.commit("customerList/replaceValue", {
            key: "enabled",
            value: val,
          });
          this.selectFilter = JSON.parse(val);
        }

        if (key == "q") {
          store.commit("customerList/replaceValue", {
            key: "q",
            value: val,
          });
          this.q = val;
        }
        if (key == "item_id") {
          store.commit("customerList/replaceValue", {
            key: "currentItemId",
            value: val,
          });
          this.currentId = val;
        }
        if (key == "item_type") {
          this.currentType = val;
          store.commit("customerList/replaceValue", {
            key: "currentItemType",
            value: val,
          });
        }

        if (key == "sort_order") {
          store.commit("customerList/replaceValue", {
            key: "sortOrder",
            value: val,
          });
          this.sortOrder = val;
        }

        if (key == "sort_by") {
          this.sortBy = val;
        }

        store.commit("customerList/replaceValue", {
          key: "clientId",
          value: this.$route.params.clientId,
        });
        console.log(this.$route.params.clientId);
      }
      this.loadItems();
    },
    loadItems() {
      const customerItemInfo = {
        client_id: this.$route.params.clientId,
        item_type: this.currentType,
        item_id: this.currentId,
      };
      store.dispatch("customerList/updateCustomerItems", customerItemInfo);
      store.dispatch("customerList/getCustomerById", customerItemInfo);
    },
    answerUpdateDialog(answer) {
      if (answer == "Yes") {
        const params = {
          client_id: this.$route.params.clientId,
          item_id: this.selectedItem.id,
        };
        let payload;
        if (this.selectedItem.enabled == true) {
          payload = {
            enabled: false,
          };
        } else {
          payload = {
            enabled: true,
          };
        }
        this.updateDialog = false;
        updateMonitoringItem(payload, params).then((result) => {
          this.snackBar = true;
          if (result.data.msg) {
            this.snackBarContent = result.data.msg;
          }
          if (result.data.errorMessage) {
            this.snackBarContent = result.data.errorMessage;
          }

          if (this.initialLoad == false) {
            this.loadItems();
          }
        });
      } else {
        this.updateDialog = false;
        return;
      }
    },
    updateItem(item) {
      this.selectedItem = item;
      this.updateDialog = true;
    },
    customerReports() {
      window.location.href =
        window.location.origin +
        "/customer/" +
        this.$route.params.clientId +
        "/reports";
    },
    customerItemsChart() {
      window.location.href =
        window.location.origin +
        "/customer/" +
        this.$route.params.clientId +
        "/itemschart";
    },
    readinessFiltered(id) {
      let countedReadinessType = [];
      let countedReadinessCount = [];
      let countedReadinessColor = [];
      let countedReadinesss = {
        type: countedReadinessType,
        count: countedReadinessCount,
        color: countedReadinessColor,
      };
      if (this.readiness !== null && this.readiness !== undefined) {
        for (let itemType of this.readiness) {
          countedReadinessCount.push(itemType.readiness);
          if (
            this.itemTypes.filter(
              (type) => type.id == itemType.monitoring_item_type_id
            ).length > 0
          ) {
            countedReadinessType.push(
              this.itemTypes.filter(
                (type) => type.id == itemType.monitoring_item_type_id
              )[0].description
            );
          } else {
            countedReadinessType.push("");
          }

          if (itemType.readiness >= 92) {
            countedReadinessColor.push("rgba(71, 201, 84, 1)");
          } else {
            countedReadinessColor.push("rgba(232, 96, 81, 1)");
          }
        }
      }
      return countedReadinesss;
    },
    itemsFiltered(id) {
      let countedItemType = [];
      let countedItemCount = [];
      let countedItemColor = [];
      let countedItems = {
        type: countedItemType,
        count: countedItemCount,
        color: countedItemColor,
      };
      if (this.summary !== null && this.summary !== undefined) {
        for (let customer of this.summary) {
          if (customer.client_id == id) {
            for (let item of customer.items) {
              countedItemCount.push(item.count);

              if (
                this.itemTypes.filter((type) => type.id == item.item_type_id)
                  .length > 0
              ) {
                countedItemType.push(
                  this.itemTypes.filter(
                    (type) => type.id == item.item_type_id
                  )[0].description
                );
              } else {
                countedItemType.push("");
              }

              if (item.count >= 92) {
                countedItemColor.push("rgba(71, 201, 84, 1)");
              } else {
                countedItemColor.push("rgba(232, 96, 81, 1)");
              }
            }
          }
        }
        return countedItems;
      }
      return countedItems;
    },

    resultsFiltered(id) {
      let countedResultType = [];
      let countedResultCount = [];
      let countedResultColor = [];
      let countedResults = {
        type: countedResultType,
        count: countedResultCount,
        color: countedResultColor,
      };
      if (this.summary !== null && this.summary !== undefined) {
        for (let customer of this.summary) {
          if (customer.client_id == id) {
            for (let result of customer.results) {
              countedResultCount.push(result.count);
              if (this.resultTypes.length > 0) {
                if (
                  this.resultTypes.filter(
                    (type) => type.id == result.result_type_id
                  ).length > 0
                ) {
                  countedResultType.push(
                    this.resultTypes.filter(
                      (type) => type.id == result.result_type_id
                    )[0].description
                  );
                } else {
                  countedResultType.push("");
                }
              }

              if (result.count >= 92) {
                countedResultColor.push("rgba(71, 201, 84, 1)");
              } else {
                countedResultColor.push("rgba(232, 96, 81, 1)");
              }
            }
          }
        }
        return countedResults;
      }
      return countedResults;
    },
    totalItems(id) {
      if (this.summary.filter((sum) => sum.client_id == id).length == 1) {
        return this.summary.filter((sum) => sum.client_id == id)[0].total_items;
      } else {
        return 0;
      }
    },
    totalResults(id) {
      if (this.summary.filter((sum) => sum.client_id == id).length == 1) {
        return this.summary.filter((sum) => sum.client_id == id)[0]
          .total_results;
      } else {
        return 0;
      }
    },
    readinessPercentage(id) {
      var filtered = this.progress.filter((e) => e != null);
      if (filtered.filter((sum) => sum.customer_id == id).length == 1) {
        return Number(
          filtered
            .filter((sum) => sum.customer_id == id)[0]
            .readiness.toFixed(0)
        );
      } else {
        return 0;
      }
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    sortArray(x, y) {
      if (x.description < y.description) {
        return -1;
      }
      if (x.description > y.description) {
        return 1;
      }
      return 0;
    },
    changeIconColors(hover, id) {
      document.getElementById(id).classList.remove("hovering");

      if (hover == "enter") {
        document.getElementById(id).classList.add("hovering");
      }
      if (hover == "exit") {
        document.getElementById(id).classList.remove("hovering");
      }
    },
    linkClick(item) {
      this.selectedItem = item;
      let routeData = this.$router.resolve({
        path: "/customer/" + this.$route.params.clientId + "/items/" + item.id,
      });
      window.open(routeData.href, "_blank");
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      const customerItemInfo = {
        client_id: this.$route.params.clientId,
        item_type: this.currentType,
      };

      store
        .dispatch("customerList/loadMoreItems", customerItemInfo)
        .then((result) => {
          if (result.data === null) {
            $state.loaded();
            return;
          }
          if (result.data.length > 0) {
            $state.loaded();
            return;
          }
          if (result.data.length === 0) {
            $state.complete();
            store.commit("customerList/firstPage");
            return;
          }
        });
    },
    addItem() {
      this.openItemDialog = true;
      setTimeout(() => {
        this.openItemDialog = false;
        document.getElementsByClassName("v-dialog")[0].style.overflowY =
          "visible";
      }, 500);
    },
    handleClick(e) {
      alert(e);
    },
  },
  computed: {
    clientId() {
      this.$route.params.clientId;
    },
    progress() {
      return store.state.customerList.progress;
    },
    summary() {
      return store.state.customerList.summary;
    },
    readiness() {
      return store.state.customerList.readiness;
    },
    client() {
      const th = this;
      let client = {
        items_chart_values: [],
        readiness_chart_values: [],
        results_chart_values: [],
        readiness_value: null,
        readiness: null,
      };
      let totalItems = store.state.customerList.summary.filter(
        (sum) => sum.client_id == th.$route.params.clientId
      );

      if (totalItems.length == 1) {
        client.items_results = `${totalItems[0].total_items} / ${totalItems[0].total_results}`;
        client.total_items = totalItems[0].total_items;
        client.total_results = totalItems[0].total_results;
      }

      client.readiness_chart_values = this.readinessFiltered(
        th.$route.params.clientId
      );
      client.items_chart_values = this.itemsFiltered(th.$route.params.clientId);
      client.results_chart_values = this.resultsFiltered(
        th.$route.params.clientId
      );
      client.readiness_value = this.readinessPercentage(
        th.$route.params.clientId
      );

      let filtered = store.state.customerList.progress.filter((e) => e != null);
      if (
        filtered.filter((cus) => cus.customer_id == th.$route.params.clientId)
          .length == 1
      ) {
        client.readiness =
          Number(
            filtered
              .filter((cus) => cus.customer_id == th.$route.params.clientId)[0]
              .readiness.toFixed(0)
          ) + "%";
      } else {
        client.readiness = "0%";
      }
      return client;
    },
    noItems() {
      return store.state.customerList.noItems;
    },
    itemTypeOptions() {
      return this.itemTypes.map((e) => {
        return { text: e.description, value: e.name };
      });
    },
    itemTypes() {
      return store.state.customerList.itemTypes.sort(this.sortArray);
    },
    resultTypes() {
      return store.state.customerList.resultTypes;
    },
    customerName() {
      return store.state.customerList.customerName;
    },
    customerItems() {
      const items = store.state.customerList.items;
      const itemIcons = {
        5: "mdi-domain",
        2: "mdi-web",
        3: "mdi-server-network",
        4: "mdi-wan",
        20: "mdi-cellphone-text",
        6: "mdi-email",
        1: "mdi-ip-network",
        26: "mdi-spider-thread",
      };

      const customIcons = {
        8: "emaildomain",
        7: "emailhost",
      };

      for (const item of items) {
        item.icon = itemIcons[item.type.id];
        item.customIcon = customIcons[item.type.id];
      }

      return items;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    summaryItemCount() {
      const summary = store.state.customerList.summary[0];
      console.log(this.currentType);
      if (summary) {
        if (
          summary.items.filter((e) => e.item_type_name == this.currentType)
            .length == 1
        ) {
          return summary.items.filter(
            (e) => e.item_type_name == this.currentType
          )[0].count;
        } else {
          return 0;
        }
      }
    },
    summaryPanels() {
      return store.state.customerList.summaryPanels;
    },
  },
  watch: {
    q: {
      handler: function (val) {
        let acceptInput = true;
        if (val.length < 1 && acceptInput) {
          store.commit("customerList/replaceValue", { key: "q", value: "" });
          acceptInput = false;
        }
        if (val.length >= 1 && acceptInput) {
          store.commit("customerList/replaceValue", { key: "q", value: val });
          acceptInput = false;
        }
        let th = this;
        window.setTimeout(function () {
          th.loadItems();
          acceptInput = true;
        }, 500);
      },
    },
    customerItems: {
      handler: function (val) {
        this.items = val;
      },
      deep: true,
      immediate: true,
    },
    summaryPanels: {
      handler: function (val) {
        this.loadedPanels = val;
      },
      deep: true,
      immediate: true,
    },
    toggleSort: {
      handler: function (val) {
        if (val == 0) {
          this.sortOrder = "asc";
        }
        if (val == 1) {
          this.sortOrder = "desc";
        }

        store.commit("customerList/replaceValue", {
          key: "sortOrder",
          value: this.sortOrder,
        });
        if (this.initialLoad == false) {
          this.loadItems();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: transparent;
  color: #fff;
}
.v-dialog {
  overflow-y: visible;
}
.displayBtns.v-btn:hover:before {
  opacity: 0;
  transition: none;
}
.displayBtns.v-btn--active:before,
.displayBtns.v-btn--active:hover:before {
  opacity: 0.18;
  transition: none;
}
.customerItems td {
  white-space: nowrap;
  min-width: 100px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.customerItems td {
  font-size: 0.9em !important;
}
.hovering .v-icon {
  color: #112748 !important;
}
</style>
